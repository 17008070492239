<template>
  <div class="purchaseDetail-box">
    <div
      style="
        height: 100px;
        border: 1px solid rgb(170, 170, 170);
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
      "
    >
      <div style="margin-left: 40px; margin-top: 20px">
        <div style="font-size: 24px; font-weight: 700; margin-bottom: 8px">
          {{ purchaseInfo.planName }}
        </div>
        <div style="font-size: 16px; color: gray; font-weight: 700">
          （任务时间：{{ handleFormatDateFunc(purchaseInfo.startTime) }}~{{
            handleFormatDateFunc(purchaseInfo.endTime)
          }}）
        </div>
      </div>
      <div style="margin-top: 25px; margin-right: 40px">
        <el-button
          @click="
            () => {
              this.$router.back();
            }
          "
          style="height: 50px; width: 70px; text-align: center"
          >返回</el-button
        >
        <el-button
          v-if="taskSubmitStatus === 1"
          type="primary"
          @click="handleSubmitPurchaseBtnClick"
          style="margin-left: 20px; height: 50px; width: 120px"
          >提交采购清单</el-button
        >
      </div>
    </div>
    <div
      style="
        padding: 20px;
        height: 700px;
        margin-top: 20px;
        border: 1px solid rgb(170, 170, 170);
        border-radius: 20px;
      "
    >
      <el-row>
        <div
          style="
            border-left: 4px solid #00f;
            padding-left: 10px;
            height: 30px;
            line-height: 30px;
            font-size: 22px;
            font-weight: 700;
          "
        >
          {{ sysOrgSchoolName }}：
        </div>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-form inline :model="purchaseCondition">
          <el-form-item
            class="formItemBoxStyle"
            prop="materialCodeName"
            label="仪器耗材名称"
          >
            <el-input
              style="width: 200px"
              v-model="purchaseCondition.materialCodeName"
              placeholder="请输入"
              @input="handleQueryPurchaseTable"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItemBoxStyle"
            prop="hasDanger"
            label="是否危化品"
          >
            <el-select
              style="width: 200px"
              v-model="purchaseCondition.hasDanger"
              placeholder="请选择"
              @change="handleQueryPurchaseTable"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="formItemBoxStyle"
            prop="hasConsumables"
            label="是否消耗品"
          >
            <el-select
              style="width: 200px"
              v-model="purchaseCondition.hasConsumables"
              placeholder="请选择"
              @change="handleQueryPurchaseTable"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleResetPurchaseTable">重置</el-button>
            <el-button type="primary" @click="handleQueryPurchaseTable"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item
            v-if="isCreatePurchaseData"
            style="position: absolute; right: 50px; top: 20px"
          >
            <span
              @click="handleAddMaterialBtnClick"
              style="
                font-size: 20px;
                cursor: pointer;
                text-decoration: underline;
                color: #00f;
              "
              >+ 添加仪器耗材</span
            >
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row>
        <el-table
          :data="purchaseTable"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          height="500"
        >
          <el-table-column
            label="国标编号"
            prop="internationCode"
          ></el-table-column>
          <el-table-column label="名称" prop="materialName">
            <template slot-scope="scoped">
              <span
                @click="handleCheckMaterialStockInfo(scoped.row)"
                style="cursor: pointer; color: #00f; text-decoration: underline"
                >{{ scoped.row.materialName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="是否消耗品">
            <template slot-scope="scope">
              {{ scope.row.materialHasConsumables ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column label="是否危化品">
            <template slot-scope="scope">
              {{ scope.row.materialHasDanger ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column label="所属科目"></el-table-column>
          <el-table-column
            label="规格型号功能"
            prop="materialSpecifications"
          ></el-table-column>
          <el-table-column label="单位" prop="materialUnit"></el-table-column>
          <el-table-column
            label="参考单价"
            prop="materialUnitPrice"
          ></el-table-column>
          <el-table-column label="标准数量">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.materialMinStandardNum &&
                  scope.row.materialMaxStandardNum
                "
              >
                {{ scope.row.materialMinStandardNum }}~{{
                  scope.row.materialMaxStandardNum
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="库存数量" prop="stockNum"></el-table-column>
          <el-table-column
            label="缺口数量"
            prop="shortageNum"
          ></el-table-column>
          <el-table-column label="采购数量">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.purchaseNum"
                onkeyup="this.value=this.value.replace(/\s/g,'')"
                type="number"
                :disabled="taskSubmitStatus !== 1"
              />
            </template>
          </el-table-column>
          <el-table-column width="80" v-if="taskSubmitStatus == 1">
            <template slot-scope="scoped">
              <i
                class="el-icon-delete"
                style="
                  color: red;
                  font-size: 22px;
                  cursor: pointer;
                  font-weight: 500;
                "
                @click="handleDeleteAddedMaterialBtnClick(scoped.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        v-if="isCreatePurchaseData || taskSubmitStatus === 2"
        style="
          margin-top: 20px;
          width: 90%;
          display: flex;
          justify-content: space-between;
          padding: 0 100px;
        "
      >
        <div>
          <el-button v-if="taskSubmitStatus === 1" @click="handleCleanTableData"
            >清空列表</el-button
          >
          <el-button
            v-if="taskSubmitStatus === 1"
            @click="handleRefreshTableData"
            >重新生成</el-button
          >
        </div>
        <div>
          <!-- <el-button
            v-if="taskSubmitStatus === 1"
            @click="handleSubmitPurchaseBtnClick"
            >提交采购需求</el-button
          > -->
          <el-button
            @click="handleDownloadPerchaseListBtnClick"
            icon="el-icon-download"
            type="primary"
            >下载采购需求清单</el-button
          >
        </div>
      </div>
      <div
        v-if="!isCreatePurchaseData && taskSubmitStatus === 1"
        style="
          position: absolute;
          height: 400px;
          background-color: #fff;
          width: 96%;
          left: 40px;
          top: 330px;
          z-index: 5;
          text-align: center;
          line-height: 400px;
        "
      >
        <el-button type="primary" @click="handleCreatePurchaseListBtnClick"
          >一键生成采购清单</el-button
        >
        <div style="margin-top: -340px; color: rgb(245, 154, 35); height: 30px">
          *
          系统将结合单位仪器耗材库存情况及仪器耗材配备标准，自动生成截止当前节点下的缺量清单。
        </div>
      </div>
    </div>

    <!-- 添加仪器耗材弹框 -->
    <el-dialog
      title="添加仪器耗材"
      :visible.sync="addMaterialDialogVisible"
      width="1200px"
    >
      <!-- 查询区域 -->
      <el-row style="margin-bottom: 20px">
        <span>编号/名称：</span>
        <el-input
          style="width: 200px; margin-right: 20px"
          v-model="materialCodeName"
          placeholder="请输入"
          @blur="handleQueryMaterialTable"
        />
        <el-button @click="handleResetMaterialTable">重置</el-button>
        <el-button type="primary" @click="handleQueryMaterialTable"
          >查询</el-button
        >
      </el-row>
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="addMaterialList"
        ref="addMaterialList"
        @row-click="handleAddMaterialRowClick"
        @select-all="handleAllRowSelect"
        @select="handleRowSelect"
      >
        <el-table-column label="编号" width="80" prop="internationCode">
        </el-table-column>
        <el-table-column label="名称" prop="materialName"> </el-table-column>
        <el-table-column label="规格型号功能" prop="materialSpecifications">
        </el-table-column>
        <el-table-column
          label="所属科目"
          prop="subjectTypeDicName"
        ></el-table-column>
        <el-table-column label="单位" prop="materialUnit"> </el-table-column>
        <el-table-column label="参考单价" prop="materialUnitPrice">
        </el-table-column>
        <el-table-column label="配备要求">
          <template slot-scope="scoped">
            {{ scoped.row.equipRequire === 1 ? "基本" : "选配" }}
          </template>
        </el-table-column>
        <el-table-column label="是否消耗品">
          <template slot-scope="scoped">
            {{ scoped.row.materialHasConsumables ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="是否危化品">
          <template slot-scope="scoped">
            {{ scoped.row.materialHasDanger ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="标准数量">
          <template slot-scope="scoped">
            {{ scoped.row.materialMinStandardNum }}~{{
              scoped.row.materialMaxStandardNum
            }}
          </template>
        </el-table-column>
        <el-table-column label="库存数量" prop="stockNum"> </el-table-column>
        <el-table-column type="selection" width="55"> </el-table-column>
      </el-table>
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangeMaterialTablePageMsg"
          @current-change="handleCurrentChangeMaterialTablePageMsg"
          :current-page="materialTablePageMsg.pageIndex"
          :page-sizes="[5, 10, 20, 50, 100, 150]"
          :page-size="materialTablePageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="materialTablePageMsg.pageTotal"
        ></el-pagination>
      </el-row>
      <div style="text-align: right">
        <el-button
          @click="
            () => {
              addMaterialDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirmAddMaterial"
          >添加</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看物品详情 -->
    <el-dialog
      title="查看实验设备详情"
      :visible.sync="checkMaterialStockInfoDialogVisible"
    >
      <checkMaterialCom
        :stockInfo="materialStockInfo"
        :imgUrl="editDialogImageList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  addMaterialForPurchaseApi,
  emptyPurchaseListApi,
  generatePurchaseListApi,
  getPurchaseDataListApi,
  getSchoolPurchaseTaskListApi,
  submitPurchaseListApi,
} from "@/api/materialManage/purchase.js";
import {
  getMaterialAccountInfoApi,
  getStockEquipmentDetailApi,
} from "@/api/materialManage/materialAccount.js";
import checkMaterialCom from "../components/checkMaterialCom.vue";
import { formatDate } from "@/utils/util";
import { getStore } from "@/utils/token.js";
export default {
  name: "purchaseDetail",
  components: {
    checkMaterialCom,
  },
  data() {
    return {
      purchaseCondition: {
        hasConsumables: "",
        hasDanger: "",
        materialCodeName: "",
      },
      isCreatePurchaseData: false,
      tablePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      purchaseTable: [],
      addMaterialDialogVisible: false,
      addMaterialList: [],
      materialCodeName: "",
      materialTablePageMsg: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      selectedMaterialList: [], // 选中的仪器耗材
      materialStockInfo: {},
      editDialogImageList: [],
      checkMaterialStockInfoDialogVisible: false,
      taskSubmitStatus: "",
      purchaseInfo: "",
      sysOrgSchoolName: "",
      tempTable: [],
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"));
    this.sysOrgSchoolName = getStore("sysOrgSchoolName");
    this.purchaseInfo = JSON.parse(this.$route.query.purchaseInfo);
    this.taskSubmitStatus = this.purchaseInfo.taskSubmitStatus;
    if (this.taskSubmitStatus == 2) {
      this.fetchPurchaseTableData();
    }
  },
  methods: {
    // 获取采购清单数据
    fetchPurchaseTableData() {
      if (!this.isCreatePurchaseData && this.taskSubmitStatus == 1) {
        return;
      }
      let taskId = this.purchaseInfo.taskId;
      // let taskId = 169148549412039;
      getPurchaseDataListApi(taskId).then((res) => {
        if (res.success) {
          this.purchaseTable = res.data;
          this.tempTable = [...res.data];
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取添加仪器耗材数据
    fetchMaterialDataInfo() {
      getMaterialAccountInfoApi(
        this.materialTablePageMsg.pageIndex,
        this.materialTablePageMsg.pageSize,
        {
          materialCodeName: this.materialCodeName,
          sysOrgSchoolId: this.sysOrgSchoolId,
        }
      ).then((res) => {
        if (res.success) {
          this.addMaterialList = res.data;
          this.materialTablePageMsg.pageTotal = res.total;
          this.handleSelectRowFunc();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加耗材表格行点击事件
    handleAddMaterialRowClick(row, column, event) {
      // 首先判断该行是否在已选的数组中
      let result = false;
      this.selectedMaterialList.forEach((item) => {
        if (item.internationCode == row.internationCode) {
          result = true;
        }
      });
      console.log(result);
      if (result) {
        // console.log("取消选择")
        for (let i = 0; i < this.selectedMaterialList.length; i++) {
          if (
            this.selectedMaterialList[i].internationCode == row.internationCode
          ) {
            this.selectedMaterialList.splice(i, 1);
            break;
          }
        }
      } else {
        // console.log("选择")
        this.selectedMaterialList.push(row);
      }
      this.$refs.addMaterialList.toggleRowSelection(row);
    },
    // 表格单选数据事件
    handleRowSelect(selection, row) {
      if (selection.indexOf(row) > -1) {
        // console.log("选择")
        this.selectedMaterialList.push(row);
      } else {
        // console.log("取消选择")
        for (let i = 0; i < this.selectedMaterialList.length; i++) {
          if (
            this.selectedMaterialList[i].internationCode == row.internationCode
          ) {
            this.selectedMaterialList.splice(i, 1);
            break;
          }
        }
      }
    },
    // 表格全选事件
    handleAllRowSelect(selection) {
      if (selection.length === 0) {
        // 取消全选
        if (this.addMaterialList.length > 0) {
          for (let i = 0; i < this.addMaterialList.length; i++) {
            for (let j = 0; j < this.selectedMaterialList.length; j++) {
              if (
                this.addMaterialList[i].internationCode ===
                this.selectedMaterialList[j].internationCode
              ) {
                this.selectedMaterialList.splice(j, 1);
                j--;
              }
            }
          }
        }
      } else {
        // 全选
        if (this.addMaterialList.length > 0) {
          for (let i = 0; i < this.addMaterialList.length; i++) {
            let temp = true;
            for (let j = 0; j < this.selectedMaterialList.length; j++) {
              if (
                this.addMaterialList[i].internationCode ===
                this.selectedMaterialList[j].internationCode
              ) {
                temp = false;
                break;
              }
            }
            temp && this.selectedMaterialList.push(this.addMaterialList[i]);
          }
        }
      }
    },
    // 调用该方法可自动将表格内需要选中的行选中
    handleSelectRowFunc() {
      if (
        this.addMaterialList.length > 0 &&
        this.selectedMaterialList.length > 0
      ) {
        for (let i = 0; i < this.addMaterialList.length; i++) {
          for (let j = 0; j < this.selectedMaterialList.length; j++) {
            if (
              this.addMaterialList[i].internationCode ===
              this.selectedMaterialList[j].internationCode
            ) {
              this.$nextTick(() => {
                this.$refs.addMaterialList.toggleRowSelection(
                  this.addMaterialList[i],
                  true
                );
              });
            }
          }
        }
      }
    },
    handleConfirmAddMaterial() {
      // console.log(this.selectedMaterialList); 拿到所有选中的耗材，发送请求
      // let fd = new FormData();
      // fd.append("taskDetailList", this.selectedMaterialList);
      // fd.append("taskId", this.purchaseInfo.taskId);
      addMaterialForPurchaseApi(
        this.purchaseInfo.taskId,
        this.selectedMaterialList.map((item) => item.sysExpConsumeMaterialId)
      ).then((res) => {
        if (res.success) {
          //this.purchaseTable = [...this.purchaseTable, ...res.data];
          this.$message.success("添加仪器耗材成功!");
          this.addMaterialDialogVisible = false;
          this.fetchPurchaseTableData();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleDeleteAddedMaterialBtnClick(row) {
      for (let i = 0; i < this.purchaseTable.length; i++) {
        if (this.purchaseTable[i].internationCode == row.internationCode) {
          this.purchaseTable.splice(i, 1);
          break;
        }
      }
    },
    // 点击一键生成数据
    handleCreatePurchaseListBtnClick() {
      let taskId = this.purchaseInfo.taskId;
      generatePurchaseListApi(taskId, +this.sysOrgSchoolId).then((res) => {
        if (res.success) {
          // this.purchaseTable = res.data;
          this.isCreatePurchaseData = true;
          this.fetchPurchaseTableData();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击添加仪器耗材按钮
    handleAddMaterialBtnClick() {
      this.materialTablePageMsg.pageIndex = 1;
      this.materialTablePageMsg.pageSize = 5;
      this.selectedMaterialList = [];
      this.fetchMaterialDataInfo();
      this.addMaterialDialogVisible = true;
    },
    // 清空列表
    handleCleanTableData() {
      emptyPurchaseListApi(this.purchaseInfo.taskId).then((res) => {
        if (res.success) {
          this.purchaseTable = [];
          this.isCreatePurchaseData = false;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击物品名称进行信息查看
    handleCheckMaterialStockInfo(row) {
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      fd.append("sysOrgSchoolId", this.sysOrgSchoolId);
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          const { data } = res;
          this.editDialogImageList = [];
          this.materialStockInfo = {
            materialName: data.materialName,
            affiliatedUnit: data.orgSchoolId,
            internationCode: data.internationCode,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            subjectDicId: data.subjectTypeDicName,
            materialUnitPrice: data.materialUnitPrice,
            equipRequire: data.equipRequire == 1 ? "基本" : "选配",
            keeper: data.keeper,
            placeRoomId: data.placeRoomName,
            snCode: data.snCode,
            materialFeature: "", // 物品特征
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.checkMaterialStockInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 重新生成
    handleRefreshTableData() {
      this.handleCleanTableData();
      this.handleCreatePurchaseListBtnClick();
    },
    // 提交采购需求
    handleSubmitPurchaseBtnClick() {
      if (!this.isCreatePurchaseData) {
        this.$message.warning("未生成采购清单");
        return;
      }
      let taskDetailList = this.purchaseTable.map((item) => ({
        sysExpConsumeMaterialId: item.sysExpConsumeMaterialId,
        purchaseNum: item.purchaseNum,
        standardNum: item.standardNum ? item.standardNum : 0,
        stockNum: item.stockNum,
      }));
      submitPurchaseListApi(this.purchaseInfo.taskId, taskDetailList).then(
        (res) => {
          if (res.success) {
            this.$message.success("提交成功");
            this.$router.back();
          } else {
            this.$message.error(res.msg);
            return;
          }
        }
      );
    },
    // 下载采购清单
    handleDownloadPerchaseListBtnClick() {},
    // 表格重置
    handleResetPurchaseTable() {
      // if (!this.isCreatePurchaseData) {
      //   return;
      // }
      this.purchaseCondition = {
        hasConsumables: "",
        hasDanger: "",
        materialCodeName: "",
      };
      // this.handleQueryPurchaseTable();
      this.purchaseTable = [...this.tempTable];
    },
    // 表格查询
    handleQueryPurchaseTable() {
      // if (!this.isCreatePurchaseData) {
      //   return;
      // }
      // this.tablePageInfo.pageIndex = 1;
      // this.tablePageInfo.pageSize = 10;
      // this.fetchPurchaseTableData();
      this.purchaseTable = [...this.tempTable];
      if (!!this.purchaseCondition.materialCodeName) {
        this.purchaseTable = this.purchaseTable.filter((it) =>
        it.materialName && it.materialName.includes(this.purchaseCondition.materialCodeName)
        );
      }
      if (this.purchaseCondition.hasDanger === 0 || this.purchaseCondition.hasDanger === 1) {
        this.purchaseTable = this.purchaseTable.filter(
          (it) => it.materialHasDanger == this.purchaseCondition.hasDanger
        );
      }
      if (this.purchaseCondition.hasConsumables === 0 || this.purchaseCondition.hasConsumables === 1) {
        this.purchaseTable = this.purchaseTable.filter(
          (it) => it.materialHasConsumables == this.purchaseCondition.hasConsumables
        );
      }
    },

    // 仪器耗材表格重置
    handleResetMaterialTable() {
      this.materialCodeName = "";
      this.handleQueryMaterialTable();
    },
    // 仪器耗材表格查询
    handleQueryMaterialTable() {
      this.materialTablePageMsg.pageIndex = 1;
      this.materialTablePageMsg.pageSize = 5;
      this.fetchMaterialDataInfo();
    },
    // 仪器耗材当前页切换
    handleCurrentChangeMaterialTablePageMsg(val) {
      this.materialTablePageMsg.pageIndex = val;
      this.materialTablePageMsg.pageSize = 5;
      this.fetchMaterialDataInfo();
    },
    // 仪器耗材页容量切换
    handleSizeChangeMaterialTablePageMsg(val) {
      this.materialTablePageMsg.pageSize = val;
      this.fetchMaterialDataInfo();
    },
    // 格式化日期
    handleFormatDateFunc(timestamp) {
      let time = new Date(timestamp);
      return formatDate(time, "yyyy/MM/dd");
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-box {
  position: relative;
}
</style>
